<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-05-29 18:18:05
-->
<template>
  <div class="optionsWrap">
    <el-form label-position="top">
      <el-form-item>
        <div slot="label" class="col333">字段属性</div>
        <div class="topic-type">
          <img src="@/assets/img/topic1.png" v-if="topicType.questionType === 'SINGLE_CHOICE_TYPE'" alt="" />
          <img src="@/assets/img/topic2.png" v-if="topicType.questionType === 'MULTIPLE_CHOICE_TYPE'" alt="" />
          <img src="@/assets/img/topic3.png" v-if="topicType.questionType === 'JUDGMENT_TYPE'" alt="" />
          <img src="@/assets/img/topic4.png" v-if="topicType.questionType === 'GAP_FILLING_TYPE'" alt="" />
          <img src="@/assets/img/topic5.png" v-if="topicType.questionType === 'ESSAY_QUESTION_TYPE'" alt="" />
          <img src="@/assets/img/icon_Grouping.png" v-if="topicType.questionType === 'GROUP_TYPE'" alt="">
          <span>{{ topicType.questionTypeValue }}</span>
        </div>
      </el-form-item>
      <!-- 当点击的是某个题型---开始 -->
      <div v-if="topicType.questionType !== 'GROUP_TYPE'">
        <el-form-item>
          <div slot="label" class="col333">
            题目<span class="sm-tit">（员工看到的题目）</span>
          </div>
          <el-input type="textarea" autosize v-model="questTitleForm.title" rows="1" placeholder="请输入题目" @input="_inputTopicTitle" @change="_changeTopicTitle"/>
        </el-form-item>
        <el-form-item>
          <div slot="label" class="col333">
            题目说明<span class="sm-tit">（员工看到的题目说明）</span>
          </div>
          <el-input type="textarea" autosize rows="1" style="margin-bottom: 5px;" v-model="questTitleForm.content" placeholder="请输入题目说明" @input="_inputTopicContent"></el-input>
          <el-upload
            name="files"
            ref="upload"
            drag
            multiple
            :action="uploadAction"
            :headers="uploadHeader"
            list-type="picture-card"
            :file-list="topicDescriptImgList"
            :on-success="_topicDescriptImgSuccess"
            :on-preview="_topicDescriptImgPreview"
            :on-remove="_topicDescriptImgRemove"
            :style="[{ '--uploadBgColor': uploadBgColor },
              { '--uploadBorderColor': $store.state.settings.theme }]">
            <div class="upload-box">
              <i class="el-icon-plus" :style="{ color: $store.state.settings.theme}"></i>
              <el-button type="text" style="margin-right: 0">上传图片</el-button>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <div slot="label" class="col333">
            分数<span class="sm-tit">（员工答对题目可得分值）</span>
          </div>
          <el-input v-model="questTitleForm.score" type="number" placeholder="请输入分数" @input="_inputTopicScore" @change="_changeTopicScore"/>
        </el-form-item>
        <el-form-item v-if="topicType.questionType !== 'GAP_FILLING_TYPE' && topicType.questionType !== 'ESSAY_QUESTION_TYPE'">
          <div slot="label" class="col333">选项</div>
          <div class="fx-row-cen" v-for="(item,index) in questTitleForm.options" :key="index" style="margin-bottom:5px;">
            <span class="col666 font14">选项{{ index + 1 }}</span>
            <el-input class="fx-1 ml16" v-model="item.optionName" clearable placeholder="请输入" :disabled="topicType.questionType === 'JUDGMENT_TYPE'" @input="_optionItemInput(item,index)" @change="_optionItemChange(item,index)"/>
            <i class="el-icon-delete" v-if="topicType.questionType !== 'JUDGMENT_TYPE'" @click="_handlerDelete(questTitleForm.options,item,index)" style="color: #FF4F4F;margin-left: 5px;"></i>
          </div>
          <div class="tc mt12" v-if="topicType.questionType !== 'JUDGMENT_TYPE'">
            <el-button type="primary" icon="el-icon-plus" plain round @click="_handlerAddOption" :style="{'--lock-btn': $store.state.settings.theme}">添加选项</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="topicType.questionType !== 'ESSAY_QUESTION_TYPE'">
          <div slot="label" class="col333">正确答案<span class="sm-tit" v-if="topicType.questionType === 'GAP_FILLING_TYPE'">（多个填空请以【 。】分隔）</span></div>
          <!-- 单选题&&判断题 start -->
          <el-radio-group v-model="questTitleForm.answer" @input="_radioInput" v-if="topicType.questionType === 'SINGLE_CHOICE_TYPE' || topicType.questionType === 'JUDGMENT_TYPE'">
            <el-radio style="margin-bottom: 5px;" v-for="(item,index) in questTitleForm.options" :key="index" :label="`选项${index + 1}`">选项{{ index + 1 }}</el-radio>
          </el-radio-group>
          <!-- 单选题&&判断题 end -->
          <!-- 复选框 start -->
          <el-checkbox-group v-model="questTitleForm.answerCheck" @change="_checkChange" v-if="topicType.questionType === 'MULTIPLE_CHOICE_TYPE'">
            <el-checkbox v-for="(item,index) in questTitleForm.options" :key="index" :label="`选项${index + 1}`" >选项{{ index + 1 }}</el-checkbox>
          </el-checkbox-group>
          <!-- 复选框 end -->
          <el-input type="textarea" autosize v-if="topicType.questionType === 'GAP_FILLING_TYPE'" v-model="questTitleForm.answer" @input="_answerInput" placeholder="请输入答案"></el-input>
        </el-form-item>
        <el-form-item>
          <div slot="label" class="col333">答案解析</div>
          <el-input style="margin-bottom: 5px;" autosize type="textarea" v-model="questTitleForm.analysis" placeholder="请输入答案解析" @input="_analysisInput"></el-input>
          <el-upload
            name="files"
            ref="upload"
            drag
            multiple
            :action="uploadAction"
            :headers="uploadHeader"
            list-type="picture-card"
            :file-list="answerImgList"
            :on-success="_answerImgSuccess"
            :on-preview="_answerImgPreview"
            :on-remove="answerImgRemove"
            :style="[ { '--uploadBgColor': uploadBgColor },
              { '--uploadBorderColor': $store.state.settings.theme }]">
            <div class="upload-box">
              <i class="el-icon-plus" :style="{ color: $store.state.settings.theme }"></i>
              <el-button type="text" style="margin-right: 0">上传图片</el-button>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <div slot="label" class="col333">其他说明</div>
          <el-input type="textarea" autosize rows="1" style="margin-bottom: 5px;" v-model="questTitleForm.otherInstructions" placeholder="请输入其他说明" @input="_otherInstructionsInput"></el-input>
        </el-form-item>
      </div>
      <!-- 当点击的是某个题型---结束 -->
      <!-- 当点击的是分组时---开始 -->
      <div v-if="topicType.questionType === 'GROUP_TYPE'">
      <el-form-item>
          <div slot="label" class="col333">
            分组名称<span class="sm-tit"></span>
          </div>
          <el-input type="textarea" autosize v-model="questGroupNameForm.groupName" rows="1" placeholder="请输入分组名称" @input="_inputGroupNameTitle" @change="_changeGroupNameTitle"/>
        </el-form-item>
      </div>
      <!-- 当点击的是分组时---结束 -->
    </el-form>
    <!-- <div class="btnwrap">
      <el-button type="primary" style="margin: 0 auto" @click="_handlerSubmitOptions">提交</el-button>
    </div> -->
    <!-- 图片预览 -->
    <el-image-viewer
      class="preview-image"
      v-if="dialogVisibleTwo"
      :url-list="[dialogImageUrlTwo]"
      :on-close="closeViewer"/>
  </div>

</template>

<script>
import { getCookie } from '@/utils/util'
export default {
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  props: {
    topicType: {
      type: Object,
      default: () => {}
    },
    currentTopicData: {
      default: () => {}
    },
    paperGroupNameInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      uploadAction: process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload', // 上传附件 链接
      uploadHeader: {
        token: getCookie('token')
      },
      topicDescriptImgList: [],
      answerImgList: [],
      checkAnswer: [], // 复选框
      uploadBgColor: '',
      questTitleForm: {
        questionBankId: '',
        questionType: null, // 题类型
        title: null, // 题目
        content: null, // 题目说明
        contentUrl: '', // 说明图片
        score: null, // 分数
        options: [], // 选项1
        answer: null, // 答案
        analysis: null, // 答案解析
        analysisUrl: '', // 答案解析图片
        otherInstructions: null // 其他说明
      },
      dialogVisibleTwo: false, // 图片预览大图
      dialogImageUrlTwo: '', // 图片预览大图
      questGroupNameForm: {},
      options: {
        optionName: null,
        label: 0
      }
    }
  },
  created () {
    this.questTitleForm.options = []
    this.uploadBgColor = this._hexToRgb(this.$store.state.settings.theme)
    this.questTitleForm.questionBankId = this.$route.query.questionBankId
  },
  watch: {
    'topicType' (newVal, oldVal) {
      // 判断题
      this.questTitleForm = this.currentTopicData
      this.questGroupNameForm = this.paperGroupNameInfo
      if (newVal?.questionType === 'JUDGMENT_TYPE') {
        this.questTitleForm.options = [
          { optionName: '正确', label: '选项1' },
          { optionName: '错误', label: '选项2' }
        ]
      }
      if (this.questTitleForm?.contentUrl || this.questTitleForm?.analysisUrl) {
        // 题目说明---回显
        if (this.questTitleForm?.contentUrl?.length !== 0 && this.questTitleForm?.contentUrl[0] !== '') {
          this.topicDescriptImgList = this.questTitleForm.contentUrl.map((item) => { return { url: item } })
        } else {
          this.topicDescriptImgList = []
        }
        // 答案解析图片--回显
        if (this.questTitleForm?.analysisUrl?.length !== 0 && this.questTitleForm?.analysisUrl[0] !== '') {
          this.answerImgList = this.questTitleForm.analysisUrl.map((item) => { return { url: item } })
        } else {
          this.answerImgList = []
        }
      }
    }
  },
  methods: {
    // 图片上传成功---题目内容
    _topicDescriptImgSuccess (response, file, fileList) {
      const tempContentUrl = []
      this.topicDescriptImgList = fileList
      if (fileList.length !== 0) {
        fileList.forEach((element) => {
          if (element.response) {
            tempContentUrl.push({
              url: element.response.data[0].url
            })
          } else {
            tempContentUrl.push({ url: element.url })
          }
        })
      }
      this.questTitleForm.contentUrl = tempContentUrl.map((item) => { return item.url })
      this.$emit('topicPictureUpload', this.questTitleForm)
    },
    // 题目上传图片预览
    _topicDescriptImgPreview (file) {
      this.dialogVisibleTwo = true
      this.dialogImageUrlTwo = file.url
    },
    // 删除图片---题目内容
    _topicDescriptImgRemove (file, fileList) {
      const tempContentUrl = this.questTitleForm.contentUrl.map((item) => { return { url: item } })
      tempContentUrl.forEach((element, index) => {
        if (file.response) {
          if (element.url === file.response.data[0].url) {
            tempContentUrl.splice(index, 1)
          }
        } else {
          if (element.url === file.url) {
            tempContentUrl.splice(index, 1)
          }
        }
      })
      this.topicDescriptImgList = tempContentUrl
      this.questTitleForm.contentUrl = tempContentUrl.map((item) => { return item.url })
      this.$emit('topicPictureUpload', this.questTitleForm)
    },
    // 试题解析内容--图片
    _answerImgSuccess (response, file, fileList) {
      const tempAnalysisUrl = []
      this.answerImgList = fileList
      if (fileList.length !== 0) {
        fileList.forEach((element) => {
          if (element.response) {
            tempAnalysisUrl.push({
              url: element.response.data[0].url
            })
          } else {
            tempAnalysisUrl.push({ url: element.url })
          }
        })
      }
      this.questTitleForm.analysisUrl = tempAnalysisUrl.map((item) => { return item.url })
      this.$emit('analysePictureUpload', this.questTitleForm)
    },
    // 试题解析图片预览
    _answerImgPreview (file) {
      this.dialogVisibleTwo = true
      this.dialogImageUrlTwo = file.url
    },
    closeViewer () {
      this.dialogVisibleTwo = false
      this.dialogVisibleThree = false
    },
    // 删除图片---试题解析内容
    answerImgRemove (file, fileList) {
      const tempAnalysisUrl = this.questTitleForm.analysisUrl.map((item) => { return { url: item } })
      tempAnalysisUrl.forEach((element, index) => {
        if (file.response) {
          if (element.url === file.response.data[0].url) {
            tempAnalysisUrl.splice(index, 1)
          }
        } else {
          if (element.url === file.url) {
            tempAnalysisUrl.splice(index, 1)
          }
        }
      })
      this.answerImgList = tempAnalysisUrl
      this.questTitleForm.analysisUrl = tempAnalysisUrl.map((item) => { return item.url })
      this.$emit('analysePictureUpload', this.questTitleForm)
    },
    // 添加选项
    _handlerAddOption () {
      this.options = {
        optionName: null,
        label: `选项${this.questTitleForm?.options.length + 1}`
      }
      const data = JSON.parse(JSON.stringify(this.options))
      this.questTitleForm.options.push(JSON.parse(JSON.stringify(data)))
      this.$emit('optionItemAddOrDelete', this.questTitleForm)
    },
    _handlerDelete (options, item, index) {
      if (options.length === 1) {
        return this.$message.error('选择题必须有选项！')
      }
      this.questTitleForm.options.splice(index, 1)
      this.$emit('optionItemAddOrDelete', this.questTitleForm)
    },
    // 提交
    _handlerSubmitOptions () {
      if (!this.questTitleForm.title) {
        return this.$message({ message: '请输入题目', type: 'warning' })
      }
      if (!this.questTitleForm.score) {
        return this.$message({ message: '请输入分数', type: 'warning' })
      }
      // 单选，多选，判断
      if (this.topicType.questionType === 'SINGLE_CHOICE_TYPE' || this.topicType.questionType === 'MULTIPLE_CHOICE_TYPE' || this.topicType.questionType === 'JUDGMENT_TYPE') {
        let flagOptions = true
        this.questTitleForm.options.forEach(item => {
          if (!item.optionName) {
            flagOptions = false
          }
        })
        if (!flagOptions) return this.$message({ message: '请完善选项', type: 'warning' })
      }
      if (this.topicType.questionType === 'MULTIPLE_CHOICE_TYPE') {
        this.questTitleForm.answer = this.checkAnswer.join(',')
      }
      if (!this.questTitleForm.answer && this.topicType.questionType !== 'ESSAY_QUESTION_TYPE') {
        return this.$message({ message: '请选择正确答案', type: 'warning' })
      }
      console.log(this.questTitleForm, 'questTitleForm')
      this.questTitleForm.questionType = this.topicType.questionType
      this.form = { ...this.questTitleForm, questionType: this.topicType.questionType }
      this.$emit('submitOption', this.questTitleForm)
      this.resetForm()
    },
    resetForm () {
      this.questTitleForm = {
        questionType: this.listType?.topicType, // 题类型
        title: null, // 题目
        content: null, // 题目说明
        contentUrl: '', // 说明图片
        score: null, // 分数
        options: this.topicType.questionType === 'SINGLE_CHOICE_TYPE' || this.topicType.questionType === 'MULTIPLE_CHOICE_TYPE' ? [JSON.parse(JSON.stringify(this.options))] : [], // 选项1
        answer: null, // 答案
        analysis: null, // 答案解析
        analysisUrl: '', // 答案解析图片
        otherInstructions: '' // 其他说明
      }
      this.topicDescriptImgList = []
      this.answerImgList = []
    },
    // 悬浮颜色
    _hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      this.$forceUpdate()
      return result
        ? 'rgba' +
            '(' +
            parseInt(result[1], 16) +
            ',' +
            parseInt(result[2], 16) +
            ',' +
            parseInt(result[3], 16) +
            ',' +
            0.2 +
            ')'
        : null
    },
    // 输入分组名称
    _changeGroupNameTitle () {
      if (!this.questGroupNameForm.groupName) {
        this.$message.error('分组名称不能为空!')
        this.questGroupNameForm.groupName = '分组名称'
      }
      this.$emit('topicGroupNameInput', this.questGroupNameForm)
    },
    _inputGroupNameTitle () {
      this.$emit('topicGroupNameInput', this.questGroupNameForm)
    },
    // 输入题目
    _changeTopicTitle () {
      if (!this.questTitleForm.title) {
        this.$message.error('题目不能为空!')
        this.questTitleForm.title = '题目'
      }
      this.$emit('topicTitleInput', this.questTitleForm)
    },
    _inputTopicTitle () {
      console.log(this.questTitleForm, 'formform----')
      this.$emit('topicTitleInput', this.questTitleForm)
    },
    // 输入题目说明
    _inputTopicContent () {
      this.$emit('topicContentInput', this.questTitleForm)
    },
    // 输入分数
    _changeTopicScore () {
      if (!this.questTitleForm.score) {
        this.$message.error('分数不能为空!')
        this.questTitleForm.score = 5
      }
      this.$emit('topicScoreInput', this.questTitleForm)
    },
    _inputTopicScore () {
      this.$emit('topicScoreInput', this.questTitleForm)
    },
    // 选项输入
    _optionItemChange (opItem, opIndex) {
      console.log(opItem, 'opItem')
      if (!opItem?.optionName) {
        this.$message.error('不能为空!')
        opItem.optionName = '选项' + (opIndex + 1)
      }
      this.questTitleForm.opIndex = opIndex
      this.$emit('optionItemInput', this.questTitleForm)
    },
    _optionItemInput (opItem, opIndex) {
      this.questTitleForm.opIndex = opIndex
      this.$emit('optionItemInput', this.questTitleForm)
    },
    // 单项选择
    _radioInput () {
      console.log(this.questTitleForm, 'this.questTitleForm')
      this.$emit('radioSelectInput', this.questTitleForm)
    },
    // 多项选择
    _checkChange () {
      if (this.questTitleForm.answerCheck.length === 0) {
        this.$message.error('答案不能为空!')
        this.questTitleForm.answerCheck = ['选项1']
      }
      this.$emit('checkSelectChange', this.questTitleForm)
    },
    // 答案
    _answerInput () {
      this.$emit('answerInput', this.questTitleForm)
    },
    // 答案解析
    _analysisInput () {
      this.$emit('analysisInput', this.questTitleForm)
    },
    // 其他说明
    _otherInstructionsInput () {
      this.$emit('otherInstructionsInput', this.questTitleForm)
    }
  }
}
</script>

<style lang="scss" scoped>
.topic-type {
  width: 105px;
  border: 1px solid #DCDCDC;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 3px;
  margin-bottom: 12px;
  box-sizing: border-box;
  cursor: pointer;
  span{
    display: inline-block;
  }
  img{
   width: 24px;
   height: 24px;
   margin-right: 5px;
   margin-top: -5px;
  }
}
/deep/.el-upload--picture-card {
  background: var(--uploadBgColor) !important;
  border-color: var(--uploadBorderColor) !important;
  width: 90px;
  height: 32px;
}
/deep/.el-upload-dragger {
  width: 90px !important;
  height: 32px !important;
  background-color: transparent;
  border: 1px dashed transparent;
  .upload-box {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-plus {
      font-size: 17px;
    }
  }
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 50px;
  height: 50px;
}
/deep/.el-upload-list--picture-card .el-progress {
  width: 50px !important;
}
/deep/.el-upload-list__item-actions{
  // position: relative;
  // display: flex!important;
  // align-items: center!important;
  // justify-content: center!important;
  font-size: 16px!important;
}
/deep/.el-upload-list__item-delete{
  margin-left: 7px!important;
}
/deep/.el-progress-circle {
  width: 50px !important;
  height: 50px !important;
}
</style>
